.shot-modal{
  display: grid;
  grid-template-columns: auto 350px;
  width: 1300px;
  height: 800px;
  background-color: var(--color-light);
  border-radius: 20px;
  border: solid 1px var(--color-gray-300);
  box-shadow: var(--shadow-md);

  &__image{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 30px;
    
    & img {
      max-height: 700px;
      border-radius: 10px;
      transition: .3s ease;
    }
  }

  &__details{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    padding: 30px;
    border-left: solid 1px var(--color-gray-300);
  }
}


