.link {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 1rem;
  width: 200px;
  border: 1px solid var(--color-gray-200);
  transition: 0.3s ease;
  cursor: pointer;

  @media (max-width: 600px) {
    width: 100%;
  }

  &:hover {
    background-color: var(--color-gray-100);
  }

  & p {
    opacity: 0.5;
    transition: 0.3s ease;
  }

  &:hover p {
    opacity: 1;
  }
}
