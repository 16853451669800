.menu-modal{
  width: 350px;
  height: 90%;
  float: right;
  padding: 15px;
  margin: 30px;
  background-color: var(--color-light-100);
  border-radius: 20px;
  border: solid 1px var(--color-gray-300);
  box-shadow: var(--shadow-md);
  // backdrop-filter: blur(10px);
  animation: slideIn 0.3s ease;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}