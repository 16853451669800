.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0px;

  &__ul {
    display: flex;
    flex-direction: row;
    gap: 10px;
    list-style: none;

    & li {
      opacity: .5;
      transition: 0.3s ease;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      &.active {
        opacity: 1;
      }
    }
  }
}
