.profile {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: var(--color-gray-50);
    border: 1px solid var(--color-gray-300);
    height: 230px;

    @media (max-width: 700px) {
      align-items: inherit;
      flex-direction: column;
      height: auto;
      padding: 20px;
    }

    &__info {
      max-width: 300px;

      @media (max-width: 700px) {
        max-width: unset;
      }
    }

    &__basic {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;

      // background-color: var(--color-gray-100);
      border: 1px solid var(--color-gray-200);
      padding: 20px;
      height: 100%;
      width: 350px;
    }

    &__exp {
      // background-color: var(--color-gray-100);
      border: 1px solid var(--color-gray-200);
      padding: 20px;
      height: 100%;
      width: 300px;

      @media (max-width: 1112px) {
        display: none;
      }
    }
  }
}

.skill__container {
  &:hover .fade-left {
    opacity: 1;
  }
}

.skill__items {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.skill {
  white-space: nowrap;
  padding: 5px 20px;
  background-color: var(--color-light);
  border: 1px solid var(--color-gray-200);
  color: var(--color-dark);
  font-size: 14px;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    border: 1px solid var(--color-highlight);
  }
}

.exp {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 40px;
  height: 40px;
  background-color: var(--color-light);
  border: 1px solid var(--color-gray-200);
  font-size: 14px;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    border: 1px solid var(--color-highlight);
  }
}

.limiter {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
