.experience {
  position: relative;
  border: 1px solid var(--color-gray-200);
  border-radius: 20px;
  // padding-bottom: 40px;
  width: 100%;
  max-height: 400px;
  overflow: auto;
  // background: linear-gradient(to top, transparent, var(--color-gray-200));

  @media (max-width: 700px) {
    max-height: none;
  }

  &__bar {
    position: sticky;
    top: 0;
    background: linear-gradient(to top, transparent, var(--color-light));
    z-index: 1;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-light);
    border: 1px solid var(--color-gray-200);
    padding: 10px;
    width: 60px;
    height: 60px;

    @media (max-width: 700px) {
      width: 45px;
      height: 45px;
      padding: 5px;
    }
  }
}

.fade {
  position: sticky;
  bottom: 0;
  height: 100px;
  width: 100%;
  background: linear-gradient(to bottom, transparent, var(--color-light));
  @media (max-width: 700px) {
    height: 0px;
  }
  // mask-image: linear-gradient(to top, var(--color-gray-200) 60%, transparent);
}

.fade-right {
  display: block;
  position: absolute;
  right: 0;
  height: 33px;
  width: 50px;
  background: linear-gradient(to right, transparent, var(--color-light));
  @media (max-width: 700px) {
    height: 0px;
  }
  // mask-image: linear-gradient(to top, var(--color-gray-200) 60%, transparent);
}

.fade-left {
  opacity: 0;
  display: block;
  position: absolute;
  left: 0;
  height: 33px;
  width: 10px;
  transition: .3s ease;
  background: linear-gradient(to left, transparent, var(--color-light));
  @media (max-width: 700px) {
    height: 0px;
  }
  // mask-image: linear-gradient(to top, var(--color-gray-200) 60%, transparent);
}
