:root {
  --color-primary: #1a1a1a;
  --color-dark: #1a1a1a;
  --color-blue-linear: linear-gradient(90deg, rgba(30, 34, 61, 0.9), rgba(45, 51, 80, 0.9));
  --color-light: #ffffff;
  --color-light-50: rgba(249, 250, 254, 0.5);
  --color-light-100: rgba(255, 255, 255, 0.95);
  --color-paragraph: rgba(26, 26, 26, 0.85);
  --color-gray-50: #ffffff;
  --color-gray-90: rgba(251, 251, 251, 0.85);
  --color-gray-100: #fafafa;
  --color-gray-200: #f2f2f2;
  --color-gray-300: #f1f1f1;
  --color-gray-400: #e1e1e1;
  --color-gray-500: #c8c8c8;

  --color-blue-100: #a0a3c4;
  --color-blue-200: #252941;
  --color-blue-400: #2b304f;
  --color-blue-500: #16182d;
  --color-highlight: rgba(114, 114, 114, 0.2);
  --shadow-md: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  --bezier-smooth: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --bezier-quick: cubic-bezier(0.2, 0.8, 0.4, 1);
  --color-blue-600: #2848ED;

//   --color-dark: #f9fafe;
//   --color-white: #fefeff;
//   --color-light: #161618;
//   --color-light-100: rgba(0, 0, 0, 0.75);
//   --color-paragraph: rgba(255, 255, 255, 0.75);
//   --color-gray-50: rgb(27, 27, 28, 0.5);
//   --color-gray-90: rgb(27, 27, 28, 0.85);
//   --color-gray-100: #292929;
//   --color-gray-200: #383838;
//   --color-gray-300: #434343;
//   --color-gray-400: #383838;
//   --color-gray-500: #2a2a2a;
}

*,
*::before,
*::after {
  outline-color: #e5e7eb;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background-color: #d3dde7;
  // color: var(--color-light);
}

html,
body {
  font-family: 'Inter', sans-serif;
  background-color: var(--color-light);
  // background-color: #E3E1D9;
  transition: background-color 0.3s ease;
}

label,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-dark);
}

p {
  color: var(--color-paragraph);
  line-height: normal;
  // font-size: 0.875rem;
}
select,
button,
textarea,
input {
  outline: none;
}

main {
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0px 20px;
  padding-bottom: 50px;
  min-height: 100vh;
}

.container {
  border: solid 1px var(--color-gray-200);

  &-sm {
    padding: 20px;
  }
}

.divider {
  &-y {
    width: 1px;
    height: 100%;
    background-color: var(--color-gray-300);
    margin: 10px 0px;
  }

  &-x {
    width: 100%;
    height: 1px;
    background-color: var(--color-gray-300);
    margin: 10px 0px;
  }
}

.btn {
  border: solid 1px transparent;
  border-radius: 0.25rem;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 100px;
  transition: 0.3s ease;
  cursor: pointer;

  &-primary {
    background-color: var(--color-primary);
    color: var(--color-light);

    &:hover {
      background-color: var(--color-paragraph);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.2;
    }
  }

  &-secondary {
    background-color: var(--color-gray-200);
    color: var(--color-dark);

    &:hover {
      background-color: var(--color-gray-300);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.2;
    }
  }

  &-outline {
    border: solid 1px var(--color-gray-200);
    border-radius: 10px;
    padding: 7px 15px;
    color: var(--color-dark);

    &:hover {
      background-color: var(--color-gray-100);
    }

    &.active {
      background-color: var(--color-gray-200);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.2;
    }
  }

  &-transparent {
    background-color: transparent;
    border-radius: 10px;
    padding: 7px 15px;
    color: var(--color-dark);

    // &:hover {
    //   background-color: var(--color-gray-100);
    // }

    &.active {
      background-color: var(--color-gray-200);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.2;
    }
  }

  &-lg {
    padding: 10px 30px;
  }

  &-icon {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
  }
}

.input {
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: 15px 50%;
  padding: 7px 40px;
  width: 100%;
  height: 40px;
  color: var(--color-dark);
  font-size: 14px;
  background-color: var(--color-gray-90);
  border: solid 1px var(--color-gray-200);
  border-radius: 12px;
  transition: 0.3s ease;

  &-primary {
    padding: 7px 15px;

    &:focus {
      border: solid 1px var(--color-highlight);
      background-color: var(--color-gray-90);
    }
  }
  &-text {
    width: 300px;
    border-radius: 100px;

    &:focus {
      border: solid 1px var(--color-highlight);
    }
  }

  &:focus {
    border: solid 1px var(--color-highlight);
  }
}

.textarea {
  padding: 10px 15px;
  width: 100%;
  min-height: 150px;
  color: var(--color-dark);
  font-size: 14px;
  background-color: var(--color-gray-90);
  border: solid 1px var(--color-gray-300);
  border-radius: 100px;
  transition: 0.3s ease;
  resize: none;

  &-primary {
    border-radius: 10px;

    &:focus {
      border: solid 1px var(--color-highlight);
    }
  }
  &-text {
    width: 300px;

    &:focus {
      border: solid 1px var(--color-highlight);
    }
  }

  &-lg {
    min-height: 200px;
  }
}

.radio {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: solid 1px var(--color-gray-300);
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  transition: 0.3s ease;

  &.active {
    border: solid 1px rgba(96, 163, 250, 0.3);
    background-color: rgba(96, 163, 250, 0.05);
  }
}

.fade-up {
  animation: fadeUp 0.3s ease both;

  &--3 {
    animation-delay: 0.3s;
  }

  &--5 {
    animation-delay: 0.6s;
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.fade-in {
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.scale-in {
  animation: scaleIn 0.3s ease both;
}

@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.dot {
  background-color: rgba(137, 199, 148, 0.3);

  &__inner {
    background-color: #89c794;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--color-light-100);
  // backdrop-filter: blur(10px);
  z-index: 10;
  animation: fadein 0.3s ease;
  overflow: auto;

  &-center {
    display: grid;
    justify-content: center;
    align-items: center;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.angle {
  position: absolute;
  top: 50%;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-gray-100);
  width: 20px;
  height: 20px;
  border-radius: 9999px;
  padding: 3px;
  opacity: 0.75;
  transition: 0.3s ease;
  cursor: pointer;
  
  &-right {
    left: auto;
    right: 10px;
  }

  &:hover {
    opacity: 1;
  }
}
