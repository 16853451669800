.shot-card {
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-50);
  border: 1px solid var(--color-gray-300);
  transition: 0.3s ease;
  cursor: pointer;

  &__img {
    
    transition: 0.3s var(--bezier-quick);
    // @media (max-width: 700px) {
      // height: 335px;
      // width: 335px;
    // }
  }

  &__info{
    transition: opacity .3s ease;
  }
  &:hover {
    // border: 1px solid var(--color-highlight);
    // background-color:rgb(162, 155, 146, 0.1);
    // border: 1px solid rgb(162, 155, 146, 0.2);
    // background-color: var(--color-gray-200);
    border: 1px solid var(--color-gray-300);

    @media (max-width: 700px) {
      // background-color: var(--color-gray-100);
      border: 1px solid var(--color-gray-200);
    }

    
    // background: linear-gradient(-45deg, transparent, #a29b9231);
    // mask-image: linear-gradient(to bottom, #a29b92 25%, transparent);
  }

  &:hover &__info{
    opacity: .5;
  }
}
