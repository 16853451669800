.edit-profile-modal{
  display: grid;
  grid-template-columns: 250px auto;
  width: 900px;
  height: 600px;
  background-color: var(--color-light);
  border-radius: 20px;
  border: solid 1px var(--color-gray-200);
  box-shadow: var(--shadow-md);

  &__options{
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  &__form{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px 50px;
    border-left: solid 1px var(--color-gray-300);
  }
}


