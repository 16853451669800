.case{
  background-color: var(--color-light);
  border: 1px solid var(--color-gray-300);
  border-radius: 10px;
  max-width: 700px;
  padding: 30px;
  box-shadow: var(--shadow-md);

  @media (max-width: 700px) {
    padding: 20px 20px;
    box-shadow: 0px 0px 0px 0px transparent;
    border: none;
    border-radius: 0px;
  }
}

.block{
  &__video{
    padding: 10px;
    background-color: var(--color-gray-100);
    border: 1px solid var(--color-gray-200);
    border-radius: 15px;
  }
}